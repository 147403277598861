<template>

    <div class="content p-0">
        <b-form @submit.prevent="handleSubmit(doSubmit())">
        <div class="card">
            <div class="card-header bg-white" style="border-bottom: 1px solid rgba(0,0,0,.125);">
                <h6 class="card-title font-weight-semibold">Pendaftaran Pasien</h6>
            </div>
            <b-card-body>
                <VStepTab :step.sync="stepTab" :last="Steps.length" :config="stepValidate">
                    <template slot="link">
                        <template v-for="(v,k) in Steps">
                            <VStepLink :step.sync="stepTab" :option="k + 1" :key="k" :clickable="(k+1) < stepTab">
                                {{ v.subtitle }}
                            </VStepLink>
                        </template>
                    </template>
                    <template slot="content">
                        <template v-for="(v,k) in Steps">
                            <VStepContent :step.sync="stepTab" :option="k+1" :key="k">
                                <template slot="title">
                                    {{ v.subtitle }}
                                </template>
                                <template slot="fieldset">
                                    <div>
                                        <validation-observer
                                            ref="VFormDataPasien"
                                        >
                                            <DataPasien v-if="stepTab == 1" />
                                        </validation-observer>
                                        <validation-observer
                                            ref="VFormPenanggungJawab"
                                        >
                                            <PenanggungJawab v-if="stepTab == 2" />
                                        </validation-observer>
                                        <validation-observer
                                            ref="VFormKewenanganInformasi"
                                        >
                                            <KewenanganInformasi v-if="stepTab == 3" />
                                        </validation-observer>
                                        
                                    </div>
                                </template>
                            </VStepContent>
                        </template>
                    </template>
                    <template slot="action">
                        <ul role="menu" aria-label="Pagination">
                            
                            <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>

                            <li :class="(stepTab==1?'disabled':'')" v-bind:aria-disabled="(stepTab==1?true:false)" @click="doPrev"><a href="javascript:" :class="'btn btn-light '+(stepTab==1?'disabled':'')" role="menuitem"><i class="icon-arrow-left13 mr-2"></i> Previous</a></li>

                            <li aria-hidden="false" aria-disabled="false" @click="doNext"><a href="javascript:" :class="'btn btn-primary '+(stepTab!=stepLast?'':'disabled')" role="menuitem">Next <i class="icon-arrow-right14 ml-2"></i></a></li>
                            

                            <li v-if="stepTab == stepLast" aria-hidden="false" aria-disabled="false"><button @click="doSubmit()"  type="button" class="btn btn-primary" role="menuitem">Submit<i class="icon-checkmark3 ml-2"></i></button></li>
                        </ul>
                    </template>
                </VStepTab> 
            </b-card-body>
        </div>
        </b-form>

        <b-modal v-model="openModalPasien" size="sm" hide-header hide-footer>
            <div class="modal-body p-0">
                <div class="text-center">
                    <div class="modal-shout-icon mb-3 d-inline-block alpha-warning text-warning rounded-circle">
                        <i class="icon-question7"></i>
                    </div>
                    <div class="mb-3">
                        <h4 class="font-weight-semibold mb-0">Simpan data pasien?</h4>
                        <span class="text-secondary">Reservasi untuk pasien dapat langsung dibuat, atau dapat dibuat nanti</span>
                    </div>
                    <div class="row justify-content-center g-1">
                        <div class="col-auto">
                        <a href="javascript:;" @click="openModalPasien=false" data-dismiss="modal" class="btn btn-outline-info">
                            Batal
                        </a>
                        </div>
 
                        <div class="col-auto">
                        <a href="javascript:;" @click="doSubmitData(false)" data-dismiss="modal" data-toggle="modal" data-target="#regPatientSaved"
                            class="btn btn-info">
                            Simpan Data
                        </a>
                        </div>
 
                        <div class="col-auto">
                        <a href="javascript:;" @click="doSubmitData(true)" class="btn btn-success btn-labeled btn-labeled-left">
                            <b><i class="icon-checkmark"></i></b>
                            Simpan dan Buat Reservasi
                        </a>
                        </div>
                    </div>
                    </div>
            </div>
        </b-modal>
    </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import VStepTab from '@/components/VStep/VStepTab.vue'
import VStepLink from '@/components/VStep/VStepLink.vue'
import VStepContent from '@/components/VStep/VStepContent.vue'

import DataPasien from './Form/DataPasien.vue'
import PenanggungJawab from './Form/PenanggungJawab.vue'
import KewenanganInformasi from './Form/KewenanganInformasi.vue'

import Gen from '@/libs/Gen.js'
const moment = require('moment')

export default{
    props:{
        row:Object,
        mrValidation:Object,
    },
    components: {
      VStepTab, VStepLink, VStepContent, DataPasien, PenanggungJawab, KewenanganInformasi
    },
    computed: {
        stepValidate(){
            return {
                disabled:false,
                validate:true,
                refs:this.$children
            }
        },
    },
    data(){
        return {
            stepTab: 1,
            stepLast: 3,
            Steps: [
                {
                    icon: 'create',
                    name: 'data-pasien',
                    subtitle: 'Data Pasien',
                    component: DataPasien,
                    completed: false
                },
                {
                    icon: 'create',
                    name: 'penanggung-jawab',
                    subtitle: 'Penanggung Jawab',
                    component: PenanggungJawab,
                    completed: false
                },
                {
                    icon: 'create',
                    name: 'kewenangan-informasi',
                    subtitle: 'Kewenangan Informasi',
                    component: KewenanganInformasi,
                    completed: false
                }
            ],

            mRoSuku: [],
            mRoBahasa: [],
            mAgama: [],
            mPekerjaan: [],
            mPendidikan: [],

            mProvinsi: [],
            mCity: [],
            mDistrict: [],
            mSubDistrict: [],

            mProvinsiDom: [],
            mCityDom: [],
            mDistrictDom: [],
            mSubDistrictDom: [],

            mProvinsiPJ: [],
            mCityPJ: [],
            mDistrictPJ: [],
            mSubDistrictPJ: [],

            mHubunganKeluarga: [],
            openModalPasien: false
        }
    },
    mounted() {
        setTimeout(()=>{
            this.stepTab = this.row.ap_last_step||1
            this.apiGetMaster()
        },1500)
    },
    methods: {
        back(){
            this.$router.back()
        },
        doPrev(){
            if(this.stepTab !== 1){
                this.stepTab = this.stepTab -1
                this.row.ap_last_step = this.stepTab
                this.autoSave(this.row)
                window.scrollTo(0,0)
            }
        },
        doNext(){
            if(this.stepTab < this.stepLast){
                if(this.stepTab == 1){
                    let dob = this.row.ap_dob
                    if(dob.length == 10){
                        let dobdata = dob.split("/")
                        let date = moment(dobdata[2]+"-"+dobdata[1]+"-"+dobdata[0]).format('DD/MM/YYYY')
                        let d = new Date()
                        if(date == 'Invalid date' || dobdata[2] > d.getFullYear()){
                            return this.$swal({
                                icon: 'error',
                                title: 'Tanggal Tidak Valid'
                            })
                        }
                    }
                    this.$refs['VFormDataPasien'][0].validate().then(success=>{
                        if(success){
                            this.stepTab = this.stepTab + 1
                            this.row.ap_last_step = this.stepTab
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                this.$parent.autoScrollValidate()
                            },500)
                        }
                    })
                }
                else if(this.stepTab == 2){
                    this.$refs['VFormPenanggungJawab'][0].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab + 1
                            this.row.ap_last_step = this.stepTab        
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                this.$parent.autoScrollValidate()                                
                            },500)
                        }
                    })
                }else if(this.stepTab == 3){
                    this.$refs['VFormKewenanganInformasi'][0].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab + 1
                            this.row.ap_last_step = this.stepTab                
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                this.$parent.autoScrollValidate()
                            },500)
                        }
                    })
                }else{
                    console.log("no action")
                }
                window.scrollTo(0,0)
            }
        },
        doSubmit(){
            let inval = 0
            this.$refs['VFormDataPasien'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 1
                    inval = inval + 1
                    this.row.ap_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        this.$parent.autoScrollValidate()
                    },500)
                }
            })
            this.$refs['VFormPenanggungJawab'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 2
                    inval = inval + 1
                    this.row.ap_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        this.$parent.autoScrollValidate()
                    },500)
                    
                }
            })
            this.$refs['VFormKewenanganInformasi'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 3
                    inval = inval + 1
                    this.row.ap_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        this.$parent.autoScrollValidate()
                    },500)
                }
            })
            setTimeout(()=>{
                if(inval){
                    return this.$swal({
                        icon: 'error',
                        title: 'Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(()=>{
                                this.$parent.autoScrollValidate()
                            },500)
                        }
                    })
                }
                
                if(!inval){
                    this.openModalPasien = true
                }
            },100)
        },
        doSubmitData(isReservasi=false){
            
            this.openModalPasien = false

            let data = this.row
            data.type = 'submit-data'
            data.isReservasi = isReservasi
            data.isUGD = this.$route.query.isUGD


            this.$parent.loadingOverlay = true
            Gen.apiRest(
                "/do/"+this.$parent.modulePage,
                {data:data}, 
                "POST"
            ).then(res=>{
                this.$parent.loadingOverlay = false
                let resp = res.data
                resp.statusType = 200
                this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(result => {
                    if (result.value) {
                        if(!isReservasi){
                            this.$router.push({ name: 'RoPasienNew' }).catch(()=>{})
                        }else{
                            if(this.$route.query.isUGD){
                                this.$router.push({ name: 'UGDReservasi', params: { pageSlug: resp.data.id} }).catch(()=>{})
                            }else{
                                this.$router.push({ name: 'RoReservasi', params: { pageSlug: resp.data.id} }).catch(()=>{})
                            }
                        }
                    }
                })
            }).catch(err=>{
                this.$parent.loadingOverlay = false
                if(err){
                    err.statusType = err.status
                    err.status = "error"
                    err.title = err.response?.data?.title
                    err.message = err.response?.data?.message
                    err.messageError = err.message
                }
                this.$parent.doSetAlertForm(err)
            })
        },
        apiGetMaster(){
            let data = {
                ap_provinsi : this.row.ap_provinsi,
                ap_kota : this.row.ap_kota,
                ap_kelurahan : this.row.ap_kelurahan,
                
                ap_dom_provinsi : this.row.ap_dom_provinsi,
                ap_dom_kota : this.row.ap_dom_kota,
                ap_dom_kelurahan : this.row.ap_dom_kelurahan,
                
                arpj_dom_provinsi : this.row.arpj_dom_provinsi,
                arpj_dom_kota : this.row.arpj_dom_kota,
                arpj_dom_kelurahan : this.row.arpj_dom_kelurahan,
            }
            Gen.apiRest(
                "/get/"+this.$parent.modulePage+'/master', 
                {
                    params: data
                }
            ).then(res=>{
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
            })
        },
        autoSave: _.debounce(function (data) {
            data.type = 'auto-save'
            data.ap_last_step = this.row.ap_last_step
            Gen.apiRest(
                "/do/"+this.$parent.modulePage,
                {data:data}, 
                "POST"
            )
        },1000),
    },
    watch: {
        row: {
            handler(v) {
                this.autoSave(v)
            },
            deep: true
        },
    }
}
</script>